.transition {
  transition: all 0.4s ease 0s;;
}

header {
  padding: 40px 0px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: block;
}

.nav-section {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.sg-lg-bt {
  display: flex;
  justify-content: end;
}

.logo {
  margin-left: 40px;
}

.sg-div {
  margin-left: -50px;
  margin-right: 73px;
}

.lg-div {
  margin-right: 32px;
}

.signup, .login {
  height: 3em;
  font-size: 22px;
  font-weight: 700;
  border: none;
  border-radius: 9px;
  padding-left: 20px;
  padding-right: 20px;
}

.signup {
  background-color: #C30093;
  color: #fff;
}

.login {
  background-color: #8352D1;
  color: #fff;
}



/* For Smaller Devices */

@media screen and (max-width: 991px) {
  header {
    padding: 20px 0;
  }
}

@media screen and (max-width: 480px) {
  .nav-section {
    padding-top: 40px;
  }

  .logo {
    margin-left: 15px;
    font-size: 12px;
  }

  .sg-div {
    margin-left: 30px;
    margin-right: 27px;
  }

  .signup, .login {
    font-size: 15px;
  }
}