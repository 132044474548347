/* Change the body to avoid other files been overwritten */

.dash-body {
  background-color: #fff !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.wallet-card-section::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  display: block;
  height: 140px;
  background: #490fb4;
}

.wallet-card-section {
  position: relative;
}

.section {
  padding: 0 16px;
}

.pt-1 {
  padding-top: 8px;
}

.wallet-card {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 20px 24px;
  position: relative;
  z-index: 1;
}

.wallet-card, .balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.wallet-card, .balance, .left {
  padding-right: 10px;
}

.none {
  display: none;
}

.wallet-card, .balance, .title {
  color: #27173E;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.wallet-card, .balance, .total {
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1em;
}

h1 {
  color: #27173E;
  margin: 0 0 10px 0;
}

/* .wallet-card, .balance, .right {
  padding: 0;
} */

/* .wallet-card, .balance, .right, .button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1em;
  color: #6236FF;
  background: rgba(98,54,255,0.1);
  width: 50px;
  height: 64px;
  font-size: 26px;
  border-radius: 10px;
} */

.balance {
  display: grid;
  grid-template-columns: 2fr 0.5fr;
}

.wallet-footer {
  border-top: 1px solid #DCDCE9;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 6em;
}

.item {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  height: 1em;
}

.trans-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2em;
}

.sm-card, .lg-card {
  border: 1px solid;
  border-radius: 7px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 15%), 0 10px 10px rgb(0 0 0 / 5%);
}

.sm-card {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-right: 10px;
}

.align-center {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.lg-card {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 20px;
}

.credit {
  color: green;
  font-size: 25px;
}

.processing {
  color: blue;
  font-size: 25px;
}

.debit {
  color: red;
  font-size: 25px;
}

.trans-head {
  font-size: 25px;
  padding-top: 100px;
  /* padding-bottom: 50px; */
}

.trans-name {
  font-size: 20px;
  margin-left: 20px;
}

.item-wt {
  color: #fff;
  border: 1px solid #FF396F;
  background-color: #FF396F;
}

.item-sd {
  color: #fff;
  border: 1px solid #6236FF;
  background-color: #6236FF;
}

.add {
  color: #fff;
  border: 1px solid #da4bfd;
  background-color: #da4bfd;
}


.add, .item-wt, .item-sd {
  border-radius: 6px;
  font-weight: 900;
  height: 3em;
}

/* .item-sd, .item-wt {
  
} */

/* The Nav Section Begins*/

.dash-hd {
  height: 56px;
  background-color: #490fb4;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.harm-hd {
  display: flex;
  align-items: center;
  margin-left: 30px;
  justify-content: end;
  margin-right: 28px;
}

.logout {
  height: 31px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  border-radius: 7px;
  background-color: #E10004;
  color: #fff;
}

/* The Nav Section Ends */

.referral {
  border: 1px solid #0056E9;
  text-align: center;
  border-radius: 1em;
  margin-top: 50px;
  margin-bottom: 20px;
}