.modalOpen {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 50%;
  background-color: #fff;
}

.sendModalOpen {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.modalClose {
  display: none;
}

.modal-text {
  color: black;
  font-weight: 900;
}

.modal-head {
  text-align: center;
}

.modal-body {
  margin-left: 20px;
  margin-right: 20px;
}

.mdIn {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  width: 100%;
  outline: none;
  margin-top: 12px;
}

.mdIn:focus {
  border-color: blue;
}

.modal-bt {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -33px
}

.deposit {
  width: 80%;
  height: 2.5em;
  font-weight: 900;
  background-color: blue;
  color: white;
  border: 1px solid blue;
  border-radius: 5px;
}

.close {
  width: 80%;
  height: 2.5em;
  font-weight: 900;
  background-color: red;
  color: white;
  border: 1px solid red;
  border-radius: 5px;
}

.modal-input {
  padding-bottom: 20px;
}

.modal-error {
  color: red;
  margin-top: -22px;
  margin-left: 20px;
  font-size: 22px;
  margin-right: 20px;
}