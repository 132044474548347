.login-body {
  position: relative;
  background: url('../assest/bitcoin.png');
}

.login-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, 36%);
  border: 1px solid #fff;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #F9F871;
  box-shadow: 0 14px 28px rgb(0 0 0 / 15%), 0 10px 10px rgb(0 0 0 / 5%);
}

.card-body {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 20px;
}

.card-input {
  width: 100%;
  margin-top: 10px;
  height: 2em;
  padding: 10px;
  border: 1px solid #da4bfd;
  border-radius: 2em;
  outline: none;
  box-shadow: 0 14px 28px rgb(0 0 0 / 15%), 0 10px 10px rgb(0 0 0 / 5%);
}

.card-input::placeholder {
  font-size: 18px;
  font-weight: 700;
}

.card-h2, .card-p {
  text-align: center;
}

.card-header {
  margin-top: 50px;
}

.login-button-div {
  display: flex;
  justify-content: center;
}

.login-button {
  margin-top: 30px;
  height: 3em;
  background-color: #470fac;
  border: 1px solid #470fac;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  width: 100%;
  border-radius: 2em;
  box-shadow: 0 14px 28px rgb(0 0 0 / 15%), 0 10px 10px rgb(0 0 0 / 5%);
}

.forgot-pass {
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 480px) {
  .login-card {
    position: revert;
    padding-top: 5em;
    padding-bottom: 1.5em;
    transform: none;

  }

  .mobile {
    padding-bottom: 5.2em;
  }
}

@media screen and (min-height: 600px) and (max-height: 880px) and (max-width: 480px) {
  .login-card {
    padding-bottom: 12.9em;
    padding-top: 10em;
  }
}