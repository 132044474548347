.online_body {
  background-color: #F6F6F6;
  height: 100vh;
  padding-left: 30px;
  padding-right: 30px;
}

.online_img {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 10%;
}

.online_hd {
  display: flex;
  justify-content: center;
}

.online_bt {
  height: 3.5em;
  width: 10em;
  background-color: #420096;
  border: none;
  margin-top: 15px;
}

.bt_txt {
  color: white;
}

.online_bt:hover {
  background-color: #7423df;
}